import { GA_TRACKING_ID } from 'config/constants'

// interface IGtagEvent {
//   action: string
//   category: string
//   label: string
//   value: string
// }

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const trackPageView = (url: string): void => {
  if (typeof window === 'undefined') {
    return
  }
  window.gtag?.('config', GA_TRACKING_ID, {
    page_path: url
  })
}
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
//  const trackEvent = ({
//   action,
//   category,
//   label,
//   value
// }: IGtagEvent): void => {
//   if (typeof window === 'undefined') {
//     return
//   }
//   window.gtag?.('event', action, {
//     event_category: category,
//     event_label: label,
//     value
//   })
//   return
// }

declare global {
  interface IGtagFunction {
    (type: 'config', trackingId: string, data: { page_path: string }): void
    (
      type: 'event',
      action: string,
      data: {
        event_category: string
        event_label: string
        value: string
      }
    ): void
  }
  interface Window {
    gtag?: IGtagFunction
  }
}
