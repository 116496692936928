import { css } from '@emotion/react'

export const customCss = css`
  html {
    color: rgb(82, 95, 127);
    line-height: 1.4;
  }

  body,
  #__next {
    -moz-osx-font-smoothing: grayscale;
    -ms-flex-direction: column;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-font-feature-settings: 'pnum';
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    background: #fff;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    flex-direction: column;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-feature-settings: 'pnum';
    font-size: 17px;
    font-style: normal;
    font-variant-numeric: proportional-nums;
    font-weight: 400;
    height: 100%;
    min-height: 100%;
    text-rendering: optimizeLegibility;
    overflow: visible;
  }

  a,
  button,
  input,
  select,
  textarea {
    -webkit-tap-highlight-color: transparent;
  }

  button,
  select {
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    outline-color: initial;
    background-image: none;
    background-color: initial;
  }

  input,
  textarea,
  select,
  button {
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    margin: 0em;
  }

  ol,
  ul {
    list-style: none;
  }

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  blockquote,
  body,
  button,
  dd,
  dl,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  p,
  pre,
  ul {
    margin: 0;
    padding: 0;
  }

  :focus {
    outline: none;
  }

  a {
    text-decoration: none;
  }
`
