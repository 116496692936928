const ENV_API_URL = process.env.NEXT_PUBLIC_API_URL!
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID!
export const TAWK_WIDGET_ID = process.env.NEXT_PUBLIC_TAWK_WIDGET_ID!
export const TAWK_PROPERTY_ID = process.env.NEXT_PUBLIC_TAWK_PROPERTY_ID!

const API_URL = ENV_API_URL.replace(/\/+$/, '')

export const superloginConfig = {
  serverUrl: API_URL,
  baseUrl: '/auth',
  socialUrl: `${API_URL}/auth`,
  noDefaultEndpoint: false,
  storage: 'local',
  providers: ['google'],
  checkExpired: false,
  refreshThreshold: 0.5,
  timeout: 0
}
