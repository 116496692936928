import { useEffect } from 'react'

import { HTMLHead } from 'components/HTMLHead'
import type { AppProps } from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'
import { globalStyles } from 'styled/globalStyles'
import { trackPageView } from 'utils/googleTagMgr'

import { Global, ThemeProvider } from '@emotion/react'
import { CssReset } from '@inspectornexus/components'
import { theme } from '@inspectornexus/theme'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', (url: string) => {
  trackPageView(url)
  return NProgress.done()
})
Router.events.on('routeChangeError', () => NProgress.done())

const MyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // initTawkWidget()
  }, [])
  return (
    <>
      <HTMLHead />
      <ThemeProvider
        theme={{ ...theme, breakpoints: ['690px', '880px', '1040px'] }}
      >
        <CssReset />
        <Global styles={globalStyles} />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  )
}

export default MyApp
