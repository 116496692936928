import { memo } from 'react'

import { FacebookPixel } from 'components/FacebookPixel'
import { GoogleTagManager } from 'components/GoogleTagManager'
import Head from 'next/head'

export const HTMLHead = memo(() => (
  <Head>
    <GoogleTagManager />
    <FacebookPixel />
    <title>World-Class Home Inspection Software | Inspector Nexus</title>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
    <meta name="msapplication-TileColor" content="#5bbad5" />
    <meta name="theme-color" content="#ffffff" />
    <meta
      content="The most advanced home inspection software on the market, Inspector Nexus helps grow your inspection business with an ultra-intuitive interface and reports that your agents & clients will love."
      name="description"
    />
    <meta
      name="keywords"
      content="Home, Inspection, Software, Fast, Flexible, Easy, to, use, Intuitive, Advanced, Best, Compatible, Free, iPhone, iPad, Android, iOS, Windows, Mac"
    />
    <meta
      content="Inspector Nexus | World-Class Home Inspection Software"
      property="og:title"
    />
    <meta content="website" property="og:type" />
    <meta
      content="The most advanced home inspection software on the market. Now available for Mac, PC, iOS, and Android"
      property="og:description"
    />
    <meta content="INDEX,FOLLOW" name="ROBOTS" />
  </Head>
))

HTMLHead.displayName = 'HTMLHead'
