import { memo, useEffect } from 'react'

import { GA_TRACKING_ID } from 'config/constants'

import { useStateChecked } from '@inspectornexus/react-hooks'

export const GoogleTagManager = memo(() => {
  const [isLoaded, handleSetIsLoaded, isLoadedRef] = useStateChecked(false)

  useEffect(() => {
    if (isLoadedRef.current) {
      return
    }
    handleSetIsLoaded(true)
  }, [])
  if (!isLoaded) {
    return null
  }
  return (
    <>
      <script
        defer
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${GA_TRACKING_ID}');
`
        }}
      />
    </>
  )
})

GoogleTagManager.displayName = 'GoogleTagManager'
